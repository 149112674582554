import { GET_SERVICE_CENTER_FAILED, GET_SERVICE_CENTER_SUCCESS, GET_SUB_RECIPIENT_FAILED, GET_SUB_RECIPIENT_SUCCESS, START_LOADING, STOP_LOADING } from "../actionConstants";
import { AppDispatch } from "../store";
import { postWithOther } from "./axios";

export const getServiceCenterDetailsFromCorC = (body: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const result = await postWithOther(
      `${process.env.REACT_APP_ENV === "local"
        ? ""
        : process.env.REACT_APP_COR_C_URL
      }/api/admin/sso`,
      body,
      dispatch
    );

    if (result) {
      dispatch({
        type: GET_SERVICE_CENTER_SUCCESS,
        payload: result,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SERVICE_CENTER_FAILED,
    });
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};

export const getSubRecipientDetailsFromCorC = (body: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const result = await postWithOther(
      `${process.env.REACT_APP_ENV === "local"
        ? ""
        : process.env.REACT_APP_COR_C_URL
      }/api/admin/sso`,
      body,
      dispatch
    );

    if (result) {
      dispatch({
        type: GET_SUB_RECIPIENT_SUCCESS,
        payload: result,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SUB_RECIPIENT_FAILED,
    });
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};