//UserInterface to define the State type for the state of the reducer

import {
  HIDE_ALERT,
  REQUEST_STARTED,
  REQUEST_STOPPED,
  RESET_SESSION,
  SESSION_EXPIRED_ERROR,
  SHOW_ALERT,
  START_LOADING,
  STOP_LOADING,
  TOGGLE_SIDE_MENU,
} from "../actionConstants";

interface AppInterface {
  loading: boolean;
  isLoading: boolean;
  loadingMessage: string;
  isHidden: boolean;
  alertMessage: string;
  alertType: string;
  sessionExpired: boolean;
}

interface Actions {
  payload: any;
  type: string;
  token: string;
}
//State type for defining the state of the reducer
export type State = AppInterface;

//Initial state of the reducer of type State
export const initialState: State = {
  loading: false,
  isLoading: false,
  loadingMessage: "",
  isHidden: false,
  alertMessage: "",
  alertType: "",
  sessionExpired: false,
};
export const AppReducers = (state: State = initialState, action: Actions) => {
  //switch between action.type
  switch (action.type) {
    //if action is of type SIGN_IN or SIGN_UP return the state by setting token to the payload
    case START_LOADING: {
      return {
        ...state,
        loading: true,
        loadingMessage:
          action.payload && action.payload.message
            ? action.payload.message
            : "",
      };
    }
    case STOP_LOADING: {
      return {
        ...state,
        loading: false,
        loadingMessage: "",
      };
    }
    case REQUEST_STARTED: {
      // Debugging log
      return {
        ...state,
        isLoading: true,
      };
    }
    case REQUEST_STOPPED: {
      // Debugging log

      return {
        ...state,
        isLoading: false,
      };
    }
    case TOGGLE_SIDE_MENU:
      return {
        ...state,
        isHidden: action.payload,
      };
    case SHOW_ALERT:
      return {
        ...state,
        alertMessage: action.payload.message,
        alertType: action.payload.type,
      };
    case HIDE_ALERT:
      return {
        ...state,
        alertMessage: "",
        alertType: "",
      };
    case SESSION_EXPIRED_ERROR:
      return {
        ...state,
        sessionExpired: true,
      };
    case RESET_SESSION:
      return {
        ...state,
        sessionExpired: false,
      };
    default:
      return state;
  }
};
