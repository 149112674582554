import { useMsal } from "@azure/msal-react";
import {
  ChevronDownIcon,
  PencilSquareIcon,
  UserCircleIcon,
  TrashIcon,
  ArrowUturnDownIcon,
} from "@heroicons/react/24/outline";
import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
// import Input from "../components/TextInput.tsx";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
// @ts-ignore
import { Container } from "react-bootstrap";
import { useAppDispatch } from "../hooks/app";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  getAllOrgnization,
  getAllOrgnizationForDropdown,
  getOrgData,
  getOrgDetails,
} from "../redux/actions/orgnization";
import TextInput from "../components/Input";
import CoreKindergardenComponent from "./application_users/core-k";
import CoreAdvantageComponent from "./application_users/core-a";
import LetterLinkUserComponent from "./application_users/letter_link";
import ClassroomCoachComponent from "./application_users/core-c";
import ReadySchollAssessmentComponent from "./application_users/core-r";
import HSSelect from "../components/Select";
import {
  getOrgDataForDropdoenUser,
  getUserData,
  registerUser,
  resetUpdateUserFlag,
  updateUserDetails,
} from "../redux/actions/user";
import { validateEmailId } from "../utils";
import { USER_ROLES } from "../utils/constant";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ConfirmationDialog from "../components/Dialogs/ConfirmationDialog";
import HSDialog from "../components/Dialog";
import { cloneDeep } from "lodash";
import CorePreschoolComponent from "./application_users/core-p";
import CurriculumAssessmentComponent from "./application_users/core-cu";

interface Role {
  role_name: string;
  role_id: number;
  id: number;
  isSubDropDown: boolean;
}

export const EditUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [rowData, setRowData] = useState<any>();
  const [firstName, setFirstName] = useState<string>("");
  const [middleName, setMiddleName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [userName, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [middleNameError, setMiddleNameError] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [organization, setOrganization] = useState<any>("");
  const [orgDetails, setOrgDetails] = useState<any>("");
  const [applications, setApplications] = useState<Array<any>>([]);
  const [open, setOpen] = useState<number>(1);
  const [letterLinkValid, setLetterLinkValid] = useState<boolean>(false);
  const [RSAValid, setRSAValid] = useState<boolean>(false);
  const [CUValid, setCUValid] = useState<boolean>(false);
  const [corAdvValid, setCorAdvtValid] = useState<boolean>(false);
  const [corKValid, setCorKValid] = useState<boolean>(false);
  const [classroomCoachValid, setClassroomCoachValid] =
    useState<boolean>(false);
  const [PQAValid, setPQAValid] = useState<boolean>(false);
  const [selectedApp, setSelectedApp] = useState<any>();
  const [warningDialog, setWarningDialog] = useState(false);
  const [applicationWarning, setApplicationWarning] = useState(false);

  const userEditDetails = useSelector(
    (state: RootState) => state?.user?.userEditDetails
  );

  const userDropdownDetails = useSelector(
    (state: RootState) => state?.user?.orgDropdownUser
  );
  const org = useSelector((state: RootState) => state.organization);
  const userData = useSelector((state: RootState) => state.user);
  const userDetails = useSelector(
    (state: RootState) => state.user?.userDetails
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userData.updateUserSuccess) {
      navigate("/users", { state: undefined });
      dispatch(resetUpdateUserFlag());
    }
  }, [userData.updateUserSuccess]);

  useEffect(() => {
    if (rowData) {
      setFirstName(rowData.firstName);
      setLastName(rowData.lastName);
      setEmail(rowData.email);
      setUserName(rowData.username);
      setMiddleName(rowData.middleName);
      setPhoneNumber(rowData.phone);
      // setOrganization(rowData.orgnization);
    }
  }, [rowData]);

  useEffect(() => {
    if (id) {
      dispatch(getUserData(id));
    }
  }, [id]);

  useEffect(() => {
    if (userEditDetails && userEditDetails?.orgnization?.id) {
      dispatch(getOrgData(userEditDetails?.orgnization?.id));
      dispatch(getOrgDataForDropdoenUser(userEditDetails?.orgnization?.id));
      setRowData(userEditDetails);
      prePopulateData(userEditDetails);
    }
  }, [userEditDetails, userEditDetails?.orgnization?.id]);

  useEffect(() => {
    if (userDropdownDetails) {
      setOrganization(userDropdownDetails);
      setOrgDetails(userDropdownDetails);
    }
  }, [userDropdownDetails]);

  const handleOpen = (value: number) => setOpen(open === value ? 0 : value);

  const initialApplication = {
    id: "",
    appId: -1,
    headerTitle: "",
    logo: "",
    code: "",
    role: "",
    isDelete: false,
    userDetails: {
      applicationId: undefined,
      applicationRoleId: undefined,
      orgId: undefined,
      orgName: "",
      mainRole: "",
      siteDetails: [],
      classDetails: [],
    },
  };

  const addApplication = () => {
    const temAppList = [...applications];
    setApplications(temAppList);
  };

  const getRoleList = (code: any) => {
    const list = orgDetails?.applications?.filter(
      (item: any) => item?.applicationCode == code
    );
    return list?.[0]?.applicationRole || [];
  };

  const prePopulateData = (rowData: any) => {
    const temAppList: any = [];

    for (let i = 0; i < rowData.userDetails.length; i++) {
      const appDetails = rowData.userDetails[i];
      const { application, application_role } = appDetails;
      const menuData = getMenuListDataByCode(application.applicationCode);
      const param: any = cloneDeep(initialApplication);
      param.appId = application?.id || -1;
      param.id = application.applicationCode;
      param.headerTitle = application?.applicationName || "";
      param.logo = menuData?.logo || "";
      param.code = application.applicationCode;
      param.roles = getRoleList(application.applicationCode);
      param.selectedRole = application_role;
      param.isDelete = false;
      param.dbId = appDetails.id;
      // delete application_role["application"];
      temAppList.push({ ...appDetails, ...param });
    }
    setApplications(temAppList);
  };

  const getMenuListDataByCode = (code: string | undefined) => {
    return menuList.find((e) => e.applicationCode === code);
  };

  const onSelectOption = (value: any, type: "DELETE" | "ADD" | "UNDO") => {
    const temAppList = [...applications];
    if (type === "ADD" && isSelected(value) === -1) {
      const menuData = menuList.find(
        (e) => e.applicationCode === value.applicationCode
      );
      const tempIntialApp = { ...initialApplication };
      tempIntialApp.appId = menuData?.id || -1;
      tempIntialApp.id = value.applicationCode;
      tempIntialApp.headerTitle = menuData?.name || "";
      tempIntialApp.logo = menuData?.logo || "";
      tempIntialApp.code = value.applicationCode;
      tempIntialApp.role = value.applicationRole;
      tempIntialApp.isDelete = false;
      temAppList.push(tempIntialApp);
      setApplications(temAppList);
      handleOpen(applications.length);
      handleChipsSelected("ADD");
    } else if (type === "DELETE" || type === "UNDO") {
      handleChipsSelected(
        "DELETE",
        temAppList.findIndex((ele: any) => ele.id === value.id)
      );
      const index = temAppList.findIndex((obj: any) => obj.id === value.id);
      if (type === "DELETE") {
        if (temAppList[index].dbId) {
          temAppList[index]["isDelete"] = true;
        } else {
          temAppList.splice(index, 1);
        }
      } else {
        temAppList[index]["isDelete"] = false;
      }
      setApplications(temAppList);
    }
  };

  const handleChipsSelected = (choice: "ADD" | "DELETE", index?: any) => {
    if (choice === "ADD") {
      let data = [...areChipsSelected];
      data.push(false);
      setAreChipsSelected([...data]);
    } else if (choice === "DELETE") {
      let data = [...areChipsSelected];
      data.splice(index, 1);
      setAreChipsSelected([...data]);
    }
  };

  const isSelected = (menuId: any) => {
    return applications.findIndex(
      (element) => element.code === menuId.applicationCode
    );
  };

  const menuList = [
    {
      id: 1,
      name: "COR Advantage",
      logo: "/images/coreA.webp",
      applicationCode: "COR-A",
    },
    {
      id: 2,
      name: "COR FOR Kindergarten",
      logo: "/images/cork.webp",
      applicationCode: "COR-K",
    },
    {
      id: 3,
      name: "Classroom Coach",
      logo: "/images/classroomCoach.webp",
      applicationCode: "COR-C",
    },
    {
      id: 4,
      name: "Ready School Assessment",
      logo: "/images/logoRSA.webp",
      applicationCode: "COR-R",
    },
    {
      id: 5,
      name: "Letter Links",
      logo: "/images/letterLink.webp",
      applicationCode: "COR-L",
    },
    {
      id: 6,
      name: "Program Quality Assessment",
      logo: "",
      applicationCode: "COR-P",
    },
    {
      id: 7,
      name: "Curriculum",
      logo: "",
      applicationCode: "COR-CU",
    },
  ];

  const toggleHeader = (value: number) => {
    if (open === value) {
      handleOpen(-1);
    } else {
      handleOpen(value);
    }
  };

  const onChangeUserDetails = (value: any, type: any) => {
    if (type === "firstName") {
      setFirstName(value);
    } else if (type === "lastName") {
      setLastName(value);
    } else if (type === "middleName") {
      setMiddleName(value);
    } else if (type === "email") {
      setEmail(value);
    } else if (type === "userName") {
      setUserName(value);
    } else if (type === "organization") {
      setOrganization(value);
    } else if (type === "phone") {
      setPhoneNumber(value);
    }
  };

  const isValidUserDetails = () => {
    if (
      firstName &&
      lastName &&
      // middleName &&
      email &&
      userName &&
      organization &&
      phoneNumber
    )
      return true;
    return false;
  };

  const handleDataFromLetterLink = (data: any) => {
    if (data.mainRole?.role_id === 100000) {
      setLetterLinkValid(true);
    } else if (data.mainRole?.role_id === 100001) {
      setLetterLinkValid(false);

      if (
        data.subRole?.role_id === 100004 &&
        (data.classRoom === undefined || data.classRoom === "")
      ) {
        setLetterLinkValid(false);
      } else if (data.subRole?.role_id === 100001 && !data.userType) {
        setLetterLinkValid(false);
      } else if (data.subRole?.role_id === 100004 && data.classRoom) {
        setLetterLinkValid(true);
      } else if (data.subRole?.role_id === 100001 && data.userType) {
        setLetterLinkValid(true);
      }
    }

    const userDetail = applications.find((app) => app?.id === "COR-L");
    userDetail.userDetails.applicationId = userDetail?.appId;
    userDetail.userDetails.code = userDetail?.id;
    userDetail.userDetails.applicationRoleId =
      data?.mainRole?.role_id === 100000
        ? data?.mainRole?.id
        : data?.subRole?.id;
    userDetail.userDetails.orgId = organization?.id;
    userDetail.userDetails.orgName = organization?.name;
    userDetail.userDetails.mainRole =
      data?.mainRole?.role_id === 100000
        ? data?.mainRole?.role_name
        : data?.subRole?.role_name;

    //classroom Name
    userDetail.userDetails.other1 = data?.classRoom
      ? data?.classRoom
      : undefined;

    //user Type
    userDetail.userDetails.other2 = data?.userType
      ? data?.userType?.role_id
      : null;

    // Role_Entity;
    userDetail.userDetails.other3 =
      data?.mainRole?.role_id === 100000
        ? data?.mainRole?.role_id.toString()
        : data?.subRole?.role_id.toString();
  };

  const handleDataFromCorAdv = (data: any) => {
    if (data.mainRole?.role_id === 100000) {
      setCorAdvtValid(true);
    } else if (data?.mainRole?.role_id === 100001) {
      setCorAdvtValid(true);
    } else if (data?.mainRole?.role_id === 100003) {
      setCorAdvtValid(false);
      if (data?.selectedSites?.length > 0) {
        setCorAdvtValid(true);
      }
    } else if (data.mainRole?.role_id === 100004) {
      setCorAdvtValid(false);
      if (data?.selectedSites && data?.selectedClassrooms?.length > 0) {
        setCorAdvtValid(true);
      }
    }

    const userDetail = applications.find((app) => app?.id === "COR-A");
    userDetail.userDetails.applicationId = userDetail?.appId;
    userDetail.userDetails.code = userDetail?.id;
    userDetail.userDetails.applicationRoleId = data?.mainRole?.id;
    userDetail.userDetails.orgId = organization?.id;
    userDetail.userDetails.orgName = organization?.name;
    userDetail.userDetails.mainRole = data?.mainRole?.role_name;

    if (data?.mainRole?.role_id === 100003) {
      userDetail.userDetails.siteDetails = undefined;
      if (data?.selectedSites?.length > 0) {
        const siteDetails = data?.selectedSites?.map((site: any) => ({
          siteName: site.Center_Name,
          siteId: site._id,
        }));

        userDetail.userDetails.siteDetails = siteDetails;
      }
    } else if (data?.mainRole?.role_id === 100004) {
      userDetail.userDetails.siteDetails = undefined;
      userDetail.userDetails.classDetails = undefined;

      if (data?.selectedSites) {
        const siteDetails = {
          siteName: data?.selectedSites?.Center_Name,
          siteId: data?.selectedSites?._id,
        };

        userDetail.userDetails.siteDetails = [siteDetails];
      }

      if (data?.selectedClassrooms?.length > 0) {
        const classDetails = data?.selectedClassrooms?.map((clas: any) => ({
          className: clas.Class_Name,
          classId: clas._id,
        }));

        userDetail.userDetails.classDetails = classDetails;
      }
    }
  };

  const handleDataFromCorK = (data: any) => {
    if (data.mainRole?.role_id === 100000) {
      setCorKValid(true);
    } else if (data.mainRole?.role_id === 100002) {
      setCorKValid(true);
    } else if (data?.mainRole?.role_id === 100001) {
      setCorKValid(false);
      if (data?.selectedSites?.length > 0) {
        setCorKValid(true);
      }
    } else if (data?.mainRole?.role_id === 100004) {
      setCorKValid(false);
      if (
        data?.selectedSite &&
        data?.selectedClassrooms?.length > 0 &&
        data?.subRole
      ) {
        setCorKValid(true);
      }
    }

    const userDetail = applications.find((app) => app?.id === "COR-K");
    userDetail.userDetails.applicationId = userDetail?.appId;
    userDetail.userDetails.code = userDetail?.id;
    userDetail.userDetails.applicationRoleId = data?.mainRole?.id;
    userDetail.userDetails.orgId = organization?.id;
    userDetail.userDetails.orgName = organization?.name;
    userDetail.userDetails.mainRole = data?.mainRole?.role_name;

    if (data?.mainRole?.role_id === 100001) {
      userDetail.userDetails.siteDetails = undefined;
      if (data?.selectedSites?.length > 0) {
        const siteDetails = data?.selectedSites?.map((site: any) => ({
          siteName: site.Center_Name,
          siteId: site._id,
        }));

        userDetail.userDetails.siteDetails = siteDetails;
      }
    } else if (data?.mainRole?.role_id === 100004) {
      userDetail.userDetails.siteDetails = undefined;
      userDetail.userDetails.classDetails = undefined;

      if (data?.selectedSite) {
        const siteDetails = {
          siteName: data?.selectedSite.Center_Name,
          siteId: data?.selectedSite._id,
        };

        userDetail.userDetails.siteDetails = [siteDetails];
      }

      if (data?.selectedClassrooms?.length > 0) {
        const classDetails = data?.selectedClassrooms?.map((clas: any) => ({
          className: clas.Class_Name,
          classId: clas._id,
        }));

        userDetail.userDetails.classDetails = classDetails;
      }
      //Teacher Profile
      userDetail.userDetails.other1 = data?.subRole?.role_name;
    }
  };

  const handleDataFromClassroomCoach = (data: any) => {
    if (data?.mainRole?.role_id === 100002) {
      setClassroomCoachValid(false);
      if (data?.selectedLevel?.value === "level1") {
        setClassroomCoachValid(true);
      } else if (data?.selectedLevel?.value === "level2") {
        setClassroomCoachValid(false);
        if (data.selectedServiceCenter) {
          setClassroomCoachValid(true);
        } else {
          setClassroomCoachValid(false);
        }
      } else if (data?.selectedLevel?.value === "level3") {
        setClassroomCoachValid(false);
        if (data?.selectedServiceCenter && data?.selectedSubReceipient) {
          setClassroomCoachValid(true);
        } else {
          setClassroomCoachValid(false);
        }
      }
    } else if (data?.mainRole?.role_id === 100004) {
      setClassroomCoachValid(false);
      if (data?.subRole?.role_id === 100001) {
        if (data?.selectedSite?.length > 0 && data?.selectedHierarchy) {
          setClassroomCoachValid(true);
        } else setClassroomCoachValid(false);
      }
      if (data?.subRole?.role_id === 100006) {
        if (data?.selectedSite?.length > 0 && data?.selectedHierarchy) {
          setClassroomCoachValid(true);
        } else setClassroomCoachValid(false);
      }
      if (data?.subRole?.role_id === 100004) {
        if (
          data?.selectedSite &&
          data?.selectedClassrooms &&
          data?.selectedTeacherProfile
        ) {
          setClassroomCoachValid(true);
        } else setClassroomCoachValid(false);
      }
    }
    
    const userDetail = applications.find((app) => app?.id === "COR-C");
    userDetail.userDetails.applicationId = userDetail?.appId;
    userDetail.userDetails.code = userDetail?.id;
    userDetail.userDetails.applicationRoleId =
    data?.mainRole?.role_id === 100002
    ? data?.mainRole?.id
    : data?.subRole?.id;
    userDetail.userDetails.orgId = organization?.id;
    userDetail.userDetails.orgName = organization?.name;
    userDetail.userDetails.mainRole =
    data?.mainRole?.role_id === 100002
    ? data?.mainRole?.role_name
    : data?.subRole?.role_name;
    
        if (data?.subRole?.role_id === 100001) {
          // hierarchy
          //other 2 -> level
          //other 3 -> hierarchy Id -> id of entity/service center/ sub receipient
          
          userDetail.userDetails.siteDetails = undefined;
          if (data?.selectedSite?.length > 0) {
            const siteDetails = data?.selectedSite?.map((site: any) => ({
              siteName: site.Center_Name,
              siteId: site._id,
            }));
            
            userDetail.userDetails.siteDetails = siteDetails;
            userDetail.userDetails.other2 = "serviceCenter";
            userDetail.userDetails.other3 = data?.selectedHierarchy?.value;
          }
        }
        if (data?.subRole?.role_id === 100006) {
          // hierarchy
          //other 2 -> level
      //other 3 -> hierarchy Id -> id of entity/service center/ sub receipient

      userDetail.userDetails.siteDetails = undefined;
      if (data?.selectedSite) {
        userDetail.userDetails.siteDetails = [{ siteName: data.selectedSite.Center_Name, siteId: data.selectedSite._id }];
        userDetail.userDetails.other2 = "serviceCenter";
        userDetail.userDetails.other3 = data?.selectedHierarchy?.value;
      }
    }
    if (data?.mainRole?.role_id === 100002) {
      // hierarchy
      //other 2 -> level
      //other 3 -> Service Center Id
      //other 4 -> Sub Receipient Id
      if (data?.selectedLevel?.value === "level1") {
        userDetail.userDetails.other2 = data?.selectedLevel?.value;
        userDetail.userDetails.other3 = null;
        userDetail.userDetails.other4 = null;
      } else if (data?.selectedLevel?.value === "level2") {
        userDetail.userDetails.other2 = data?.selectedLevel?.value;
        userDetail.userDetails.other3 = data?.selectedServiceCenter?._id;
        userDetail.userDetails.other4 = null;
      } else if (data?.selectedLevel?.value === "level3") {
        userDetail.userDetails.other2 = data?.selectedLevel?.value;
        userDetail.userDetails.other3 = data?.selectedServiceCenter?._id;
        userDetail.userDetails.other4 = data?.selectedSubReceipient?._id;
      }
    } else if (data?.subRole?.role_id === 100004) {
      userDetail.userDetails.siteDetails = undefined;
      userDetail.userDetails.classDetails = undefined;

      if (data?.selectedSite) {
        const siteDetails = {
          siteName: data?.selectedSite.Center_Name,
          siteId: data?.selectedSite._id,
        };

        userDetail.userDetails.siteDetails = [siteDetails];
      }

      if (data?.selectedClassrooms) {
        const classDetails = [
          {
            className: data?.selectedClassrooms.Class_Name,
            classId: data?.selectedClassrooms._id,
          },
        ];

        userDetail.userDetails.classDetails = classDetails;
      }
      //Teacher Profile
      userDetail.userDetails.other1 = data?.selectedTeacherProfile?.label;
    }
  };

  const handleDataFromRSA = (data: any) => {
    if (data.mainRole?.role_id === 100000) {
      setRSAValid(true);
    } else if (data?.mainRole?.role_id === 200001) {
      setRSAValid(true);
    } else if (data?.mainRole?.role_id === 200002) {
      setRSAValid(false);
      if (data?.selectedSite) {
        setRSAValid(true);
      }
    } else if (data?.mainRole?.role_id === 200003) {
      setRSAValid(false);
      if (data?.selectedSite) {
        setRSAValid(true);
      }
    } else if (data?.mainRole?.role_id === 200004) {
      setRSAValid(false);
      if (data?.selectedSite) {
        setRSAValid(true);
      }
    } else if (data?.mainRole?.role_id === 200005) {
      setRSAValid(false);
      if (data?.selectedSite) {
        setRSAValid(true);
      }
    }

    const userDetail = applications.find((app) => app?.id === "COR-R");
    userDetail.userDetails.applicationId = userDetail?.appId;
    userDetail.userDetails.code = userDetail?.id;
    userDetail.userDetails.applicationRoleId = data?.mainRole?.id;
    userDetail.userDetails.orgId = organization?.id;
    userDetail.userDetails.orgName = organization?.name;
    userDetail.userDetails.mainRole = data?.mainRole?.role_name;

    if (
      data?.mainRole?.role_id !== 100000 ||
      data?.mainRole?.role_id !== 200001
    ) {
      userDetail.userDetails.siteDetails = undefined;

      if (data?.selectedSite && Array.isArray(data.selectedSite)) {
        for (let i = 0; i < data.selectedSite.length; i++) {
          const siteDetails = {
            Site_Name: data.selectedSite[i]?.Site_Name,
            _id: data.selectedSite[i]?._id,
          };
          userDetail.userDetails.siteDetails = siteDetails;
        }
      }

      if (data?.selectedSite) {
        const siteDetails = [
          {
            siteName: data?.selectedSite?.Site_Name,
            siteId: data?.selectedSite?._id,
          },
        ];
        userDetail.userDetails.siteDetails = siteDetails;
      }
    }
  };

  const handleDataFromCU = (data: any) => {
    if (data.mainRole?.role_id === 100000) {
      setCUValid(true);
    } else if (data?.mainRole?.role_id === 100001) {
      setCUValid(true);
    } else if (data?.mainRole?.role_id === 100002) {
      setCUValid(false);
      if (data?.selectedSite) {
        setCUValid(true);
      }
    } else if (data?.mainRole?.role_id === 100004) {
      setCUValid(false);
      if (data?.selectedSite) {
        setCUValid(true);
      }
    }

    const userDetail = applications.find((app) => app?.id === "COR-CU");
    userDetail.userDetails.applicationId = userDetail?.appId;
    userDetail.userDetails.code = userDetail?.id;
    userDetail.userDetails.applicationRoleId = data?.mainRole?.id;
    userDetail.userDetails.orgId = organization?.id;
    userDetail.userDetails.orgName = organization?.name;
    userDetail.userDetails.mainRole = data?.mainRole?.role_name;

    if (
      data?.mainRole?.role_id !== 100000 ||
      data?.mainRole?.role_id !== 100001
    ) {
      userDetail.userDetails.siteDetails = undefined;

      if (data?.selectedSite) {
        const siteDetails = [
          {
            siteName: data?.selectedSite?.Site_Name,
            siteId: data?.selectedSite?._id,
          },
        ];
        userDetail.userDetails.siteDetails = siteDetails;
      }
      if (data?.selectedClassroom) {
        const classDetails = [
          {
            className: data?.selectedClassroom?.Classroom_Name,
            classId: data?.selectedClassroom?._id,
          },
        ];
        userDetail.userDetails.classDetails = classDetails;
      }
      if (data?.mainRole?.role_id !== 100004) {
        userDetail.userDetails.classDetails = undefined;
      }
    }
  };

  const handleDataFromPQA = (data: any) => {
    if (data.mainRole?.role_id === 100000) {
      setPQAValid(true);
    } else if (data.mainRole?.role_id === 100001) {
      setPQAValid(true);
    } else if (data?.mainRole?.role_id === 100002) {
      setPQAValid(false);
      if (data?.selectedSites) {
        setPQAValid(true);
      }
    } else if (data?.mainRole?.role_id === 100003) {
      setPQAValid(false);
      if (data?.selectedSites) {
        setPQAValid(true);
      }
    } else if (data?.mainRole?.role_id === 100004) {
      setPQAValid(false);
      if (data?.selectedClassrooms?.length > 0) {
        setPQAValid(true);
      }
    }
    const userDetail = applications.find((app) => app?.id === "COR-P");
    userDetail.userDetails.applicationId = userDetail?.appId;
    userDetail.userDetails.code = userDetail?.id;
    userDetail.userDetails.applicationRoleId = data?.mainRole?.id;
    userDetail.userDetails.orgId = organization?.id;
    userDetail.userDetails.orgName = organization?.name;
    userDetail.userDetails.mainRole = data?.mainRole?.role_name;

    if (data?.mainRole?.role_id === 100001) {
      userDetail.userDetails.siteDetails = undefined;
      userDetail.userDetails.classDetails = undefined;
      if (data?.selectedSites?.length > 0) {
        const siteDetails = data?.selectedSites?.map((site: any) => ({
          siteName: site.Center_Name,
          siteId: site._id,
        }));
        userDetail.userDetails.siteDetails = siteDetails;
      }
    } else if (data?.mainRole?.role_id === 100004) {
      userDetail.userDetails.siteDetails = undefined;
      userDetail.userDetails.classDetails = undefined;
      if (data?.selectedSites) {
        const siteDetails = {
          siteName: data?.selectedSites.Center_Name,
          siteId: data?.selectedSites._id,
        };
        userDetail.userDetails.siteDetails = [siteDetails];
      }
      if (data?.mainRole?.role_id === 100004) {
        const classDetails = data?.selectedClassrooms?.map((clas: any) => ({
          className: clas.Class_Name,
          classId: clas._id,
        }));
        userDetail.userDetails.classDetails = classDetails;
      }
    } else if (data?.mainRole?.role_id === 100002) {
      const siteDetails = {
        siteName: data?.selectedSites?.Center_Name,
        siteId: data?.selectedSites?._id,
      };
      userDetail.userDetails.siteDetails = [siteDetails];
    } else if (data?.mainRole?.role_id === 100003) {
      if (data?.selectedSites && Array.isArray(data.selectedSites)) {
        const siteDetails = data.selectedSites.map((selectedSite: any) => ({
          siteName: selectedSite.Center_Name,
          siteId: selectedSite._id,
        }));
        userDetail.userDetails.siteDetails = siteDetails;
      }
    }
  };

  //  ******************  Heirarchy Code   ******************
  // const handleDataFromPQA = (data: any) => {
  //   if (data.mainRole?.role_id === 100000) {
  //     setPQAValid(true);
  //   } else if (data.mainRole?.role_id === 100001) {
  //     setPQAValid(false);
  //     if (data?.selectedLevel?.value === "level1") {
  //       setPQAValid(true);
  //     } else if (data?.selectedLevel?.value === "level2") {
  //       setPQAValid(false);
  //       if (data.selectedServiceCenter) {
  //         setPQAValid(true);
  //       } else {
  //         setPQAValid(false);
  //       }
  //     } else if (data?.selectedLevel?.value === "level3") {
  //       setPQAValid(false);
  //       if (data?.selectedServiceCenter && data?.selectedSubReceipient) {
  //         setPQAValid(true);
  //       } else {
  //         setPQAValid(false);
  //       }
  //     }
  //   } else if (data?.mainRole?.role_id === 100002) {
  //     setPQAValid(false);
  //     if (data?.selectedSites) {
  //       setPQAValid(true);
  //     }
  //   } else if (data?.mainRole?.role_id === 100003) {
  //     if (data?.selectedSite?.length > 0 && data?.selectedHierarchy) {
  //       setPQAValid(true);
  //     }
  //   } else if (data?.mainRole?.role_id === 100004) {
  //     setPQAValid(false);
  //     if (data?.selectedSite && data?.selectedClassrooms?.length > 0) {
  //       setPQAValid(true);
  //     }
  //   }
  //   const userDetail = applications.find((app) => app?.id === "COR-P");
  //   userDetail.userDetails.applicationId = userDetail?.appId;
  //   userDetail.userDetails.code = userDetail?.id;
  //   userDetail.userDetails.applicationRoleId = data?.mainRole?.id;
  //   userDetail.userDetails.orgId = organization?.id;
  //   userDetail.userDetails.orgName = organization?.name;
  //   userDetail.userDetails.mainRole = data?.mainRole?.role_name;

  //   if (data?.mainRole?.role_id === 100001) {
  //     userDetail.userDetails.siteDetails = undefined;
  //     if (data?.selectedLevel?.value === "level1") {
  //       userDetail.userDetails.other2 = data?.selectedLevel?.value;
  //       userDetail.userDetails.other3 = null;
  //       userDetail.userDetails.other4 = null;
  //     } else if (data?.selectedLevel?.value === "level2") {
  //       userDetail.userDetails.other2 = data?.selectedLevel?.value;
  //       userDetail.userDetails.other3 = data?.selectedServiceCenter?._id;
  //       userDetail.userDetails.other4 = null;
  //     } else if (data?.selectedLevel?.value === "level3") {
  //       userDetail.userDetails.other2 = data?.selectedLevel?.value;
  //       userDetail.userDetails.other3 = data?.selectedServiceCenter?._id;
  //       userDetail.userDetails.other4 = data?.selectedSubReceipient?._id;
  //     }
  //   } else if (data?.mainRole?.role_id === 100004) {
  //     userDetail.userDetails.siteDetails = undefined;
  //     userDetail.userDetails.classDetails = undefined;
  //     if (data?.selectedSites) {
  //       const siteDetails = {
  //         siteName: data?.selectedSites.Center_Name,
  //         siteId: data?.selectedSites._id,
  //       };
  //       userDetail.userDetails.siteDetails = [siteDetails];
  //     }
  //     if (data?.mainRole?.role_id === 100004) {
  //       const classDetails = data?.selectedClassrooms?.map((clas: any) => ({
  //         className: clas.Class_Name,
  //         classId: clas._id,
  //       }));
  //       userDetail.userDetails.classDetails = classDetails;
  //     }
  //   } else if (data?.mainRole?.role_id === 100002) {
  //     const siteDetails = {
  //       siteName: data?.selectedSites?.Center_Name,
  //       siteId: data?.selectedSites?._id,
  //     };
  //     userDetail.userDetails.siteDetails = [siteDetails];
  //     userDetail.userDetails.other2 = data?.selectedHierarchy?.level;
  //     userDetail.userDetails.other3 = data?.selectedHierarchy?.value;
  //   } else if (data?.mainRole?.role_id === 100003) {
  //     if (data?.selectedSites && Array.isArray(data.selectedSites)) {
  //       const siteDetails = data.selectedSites.map((selectedSite: any) => ({
  //         siteName: selectedSite.Center_Name,
  //         siteId: selectedSite._id,
  //       }));
  //       userDetail.userDetails.siteDetails = siteDetails;
  //       userDetail.userDetails.other2 = data?.selectedHierarchy?.level;
  //       userDetail.userDetails.other3 = data?.selectedHierarchy?.value;
  //     }
  //   }
  // };
  //  *********************************************************************************************************

  const updateUser = () => {
    if (!firstName) {
      setFirstNameError("Please enter first name");
      return;
    }
    if (!lastName) {
      setLastNameError("Please enter last name");
      return;
    }
    // if (!middleName) {
    //   setMiddleNameError("Please enter address");
    //   return;
    // }
    if (!userName) {
      setUserNameError("Please enter user name");
      return;
    }
    if (!phoneNumber) {
      setPhoneError("Please enter phone");
      return;
    }

    if (phoneNumber && phoneNumber.length !== 10) {
      setPhoneError("Phone number must be 10 digit");
      return;
    }

    if (!email) {
      setEmailError("Please enter email");
      return;
    }

    if (!validateEmailId(email)) {
      setEmailError("Please enter valid email");
      return;
    }
    const temAppList: any = [];

    let isApplicationAvailable = false;

    if (applications.length > 0) {
      for (let i = 0; i < applications.length; i++) {
        const userDetailObj = applications[i].userDetails;
        if (applications[i].dbId) {
          userDetailObj["id"] = applications[i].dbId;
        }
        userDetailObj["isDelete"] = applications[i].isDelete;
        userDetailObj["orgId"] = rowData?.orgnization?.id;
        userDetailObj["orgName"] = rowData?.orgnization?.name;

        temAppList.push(userDetailObj);
        if (!userDetailObj.isDelete) {
          isApplicationAvailable = true;
        }
      }
    }

    if (!isApplicationAvailable) {
      setApplicationWarning(true);
      return;
    }

    // applications?.map((app) => {
    //   temAppList.push(app.userDetails);
    // });

    const body = {
      id: rowData.id,
      lastName: lastName,
      firstName: firstName,
      middleName: middleName,
      role: "HighscopeOrgAdmin",
      email: email,
      username: userName,
      orgnizationId: organization?.id,
      userDetails: temAppList,
      phone: phoneNumber,
      azurId: rowData.azurId,
    };
    dispatch(updateUserDetails(body));
  };

  const [areChipsSelected, setAreChipsSelected] = useState<Array<boolean>>([]);
  const getAccourdianBoady = (data: any, index: number) => {
    // const selectedRoles = { ...type.application_role }
    switch (data.id) {
      case "COR-K":
        return (
          <CoreKindergardenComponent
            data={data}
            // selectedRoles={data.selectedRole}
            orgRole={
              userDetails?.role === USER_ROLES.HIGHSCOPE_ADMIN
                ? 100000
                : userDetails.userDetails.find(
                    (detail: any) =>
                      detail.application.applicationCode === "COR-K"
                  )?.application_role.role_id
            }
            roles={getRoleList(data.code)}
            org={organization}
            onDataChange={handleDataFromCorK}
            isChipSelected={(e) => {
              let data = [...areChipsSelected];
              data[index] = e;
              setAreChipsSelected([...data]);
            }}
          />
        );
      case "COR-A":
        return (
          <CoreAdvantageComponent
            data={data}
            roles={getRoleList(data.code)}
            org={organization}
            orgRole={
              userDetails?.role === USER_ROLES.HIGHSCOPE_ADMIN
                ? 100000
                : userDetails.userDetails.find(
                    (detail: any) =>
                      detail.application.applicationCode === "COR-A"
                  )?.application_role.role_id
            }
            onDataChange={handleDataFromCorAdv}
            isChipSelected={(e) => {
              let data = [...areChipsSelected];
              data[index] = e;
              setAreChipsSelected([...data]);
            }}
          />
        );
      case "COR-L":
        return (
          <LetterLinkUserComponent
            data={data}
            roles={getRoleList(data.code)}
            org={organization}
            orgRole={
              userDetails?.role === USER_ROLES.HIGHSCOPE_ADMIN
                ? 100000
                : userDetails.userDetails.find(
                    (detail: any) =>
                      detail.application.applicationCode === "COR-L"
                  )?.application_role.role_id
            }
            onDataChange={handleDataFromLetterLink}
            isChipSelected={(e) => {
              let data = [...areChipsSelected];
              data[index] = e;
              setAreChipsSelected([...data]);
            }}
          />
        );
      case "COR-C":
        return (
          <ClassroomCoachComponent
            data={data}
            // selectedRoles={data.selectedRole}
            roles={getRoleList(data.code)}
            org={organization}
            orgRole={
              userDetails?.role === USER_ROLES.HIGHSCOPE_ADMIN
                ? 100000
                : userDetails?.userDetails.find(
                    (detail: any) =>
                      detail.application.applicationCode === "COR-C"
                  )?.application_role.role_id
            }
            onDataChange={handleDataFromClassroomCoach}
            isChipSelected={(e) => {
              let data = [...areChipsSelected];
              data[index] = e;
              setAreChipsSelected([...data]);
            }}
            moduleType={"Edit"}
            userId={id}
          />
        );
      case "COR-R":
        return (
          <ReadySchollAssessmentComponent
            data={data}
            roles={getRoleList(data.code)}
            // org={org?.orgnizationData?.results["COR-R"]?.entity_data}
            org={organization}
            orgRole={
              userDetails?.role === USER_ROLES.HIGHSCOPE_ADMIN
                ? 100000
                : userDetails.userDetails.find(
                    (detail: any) =>
                      detail.application.applicationCode === "COR-R"
                  )?.application_role.role_id
            }
            onDataChange={handleDataFromRSA}
            isChipSelected={(e) => {
              let data = [...areChipsSelected];
              data[index] = e;
              setAreChipsSelected([...data]);
            }}
          />
        );
      case "COR-CU":
        return (
          <CurriculumAssessmentComponent
            data={data}
            roles={getRoleList(data.code)}
            org={organization}
            orgRole={
              userDetails?.role === USER_ROLES.HIGHSCOPE_ADMIN
                ? 100000
                : userDetails.userDetails.find(
                    (detail: any) =>
                      detail.application.applicationCode === "COR-CU"
                  )?.application_role.role_id
            }
            onDataChange={handleDataFromCU}
            isChipSelected={(e) => {
              let data = [...areChipsSelected];
              data[index] = e;
              setAreChipsSelected([...data]);
            }}
          />
        );
      case "COR-P":
        return (
          <CorePreschoolComponent
            data={data}
            // selectedRoles={data.selectedRole}
            roles={getRoleList(data.code)}
            orgRole={
              userDetails?.role === USER_ROLES.HIGHSCOPE_ADMIN
                ? 100000
                : userDetails.userDetails.find(
                    (detail: any) =>
                      detail.application.applicationCode === "COR-P"
                  )?.application_role.role_id
            }
            org={organization}
            onDataChange={handleDataFromPQA}
            isChipSelected={(e) => {
              let data = [...areChipsSelected];
              data[index] = e;
              setAreChipsSelected([...data]);
            }}
          />
        );
      default:
        return null;
    }
  };

  const getBadge = (type: string) => {
    switch (type) {
      case "COR-K":
        return corKValid ? (
          <CheckBadgeIcon className="w-8 h-8 ml-2 " color={"#07e015"} />
        ) : (
          <ExclamationCircleIcon className="w-8 h-8 ml-2" color={"#f2382e"} />
        );
      case "COR-A":
        return corAdvValid ? (
          <CheckBadgeIcon className="w-8 h-8 ml-2 " color={"#07e015"} />
        ) : (
          <ExclamationCircleIcon className="w-8 h-8 ml-2" color={"#f2382e"} />
        );
      case "COR-L":
        return letterLinkValid ? (
          <CheckBadgeIcon className="w-8 h-8 ml-2 " color={"#07e015"} />
        ) : (
          <ExclamationCircleIcon className="w-8 h-8 ml-2" color={"#f2382e"} />
        );
      case "COR-R":
        return RSAValid ? (
          <CheckBadgeIcon className="w-8 h-8 ml-2 " color={"#07e015"} />
        ) : (
          <ExclamationCircleIcon className="w-8 h-8 ml-2" color={"#f2382e"} />
        );
      case "COR-CU":
        return CUValid ? (
          <CheckBadgeIcon className="w-8 h-8 ml-2 " color={"#07e015"} />
        ) : (
          <ExclamationCircleIcon className="w-8 h-8 ml-2" color={"#f2382e"} />
        );
      case "COR-C":
        return classroomCoachValid ? (
          <CheckBadgeIcon className="w-8 h-8 ml-2 " color={"#07e015"} />
        ) : (
          <ExclamationCircleIcon className="w-8 h-8 ml-2" color={"#f2382e"} />
        );
      case "COR-P":
        return PQAValid ? (
          <CheckBadgeIcon className="w-8 h-8 ml-2 " color={"#07e015"} />
        ) : (
          <ExclamationCircleIcon className="w-8 h-8 ml-2" color={"#f2382e"} />
        );
      case "COR-AL":
        return null;
      default:
        return null;
    }
  };

  const onDeleteApp = () => {
    onSelectOption(selectedApp, "DELETE");
    setWarningDialog(false);
  };

  const areApplicationRolesSelected = () => {
    let flag = true;
    if (applications.length === areChipsSelected.length) {
      if (!areChipsSelected.includes(false)) {
        flag = false;
      }
    }
    return flag;
  };

  return (
    <>
      <>
        <Container className="h-full bg-white p-4 min-w-[82vw] font-['poppins'] overflow-auto ">
          <div className="h-full res_height ">
            <div className="flex flex-row justify-center  bg-[#264390] max-w-[300px]  mx-auto text-white font-medium p-2 min-h-fit max-h-full rounded-sm ">
              <UserCircleIcon className="w-8 h-8 mr-2 " />
              <span className="text-2xl font-medium ">Edit User</span>
            </div>
            <div className="Card my-3 rounded-md shadow-sm bg-[#ececec]">
              <div className="p-4 xl:h-[57vh] 2xl:h-[65vh] 1xl:h-[58vh] lg:h-[55vh] md:h-[55vh] overflow-auto">
                <span className="font-semibold text-xl">
                  Enter User Details
                </span>
                <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 px-1 py-3 gap-y-1 gap-x-5">
                  <TextInput
                    placeholder={"First Name"}
                    required={true}
                    error={firstNameError}
                    value={firstName}
                    label={"First Name"}
                    onChange={(e: any) => {
                      onChangeUserDetails(e.target.value, "firstName");
                      setFirstNameError("");
                    }}
                  />
                  <TextInput
                    label={"Middle Name"}
                    placeholder="Middle Name"
                    // required={true}
                    error={middleNameError}
                    value={middleName}
                    onChange={(e: any) => {
                      onChangeUserDetails(e.target.value, "middleName");
                      setMiddleNameError("");
                    }}
                  />
                  <TextInput
                    label={"Last Name"}
                    placeholder="Last Name"
                    required={true}
                    error={lastNameError}
                    value={lastName}
                    onChange={(e: any) => {
                      onChangeUserDetails(e.target.value, "lastName");
                      setLastNameError("");
                    }}
                  />
                  <TextInput
                    label={"Email Address"}
                    placeholder="Email Address"
                    required={true}
                    value={email}
                    disabled={true}
                    // readOnly={true}
                    error={emailError}
                    onChange={(e: any) => {
                      onChangeUserDetails(e.target.value, "email");
                      setEmailError("");
                    }}
                  />
                  <TextInput
                    label={"User Name"}
                    placeholder="Username"
                    required={true}
                    error={userNameError}
                    value={userName}
                    disabled={true}
                    // readOnly={true}
                    onChange={(e: any) => {
                      onChangeUserDetails(e.target.value, "userName");
                      setUserNameError("");
                    }}
                  />
                  <TextInput
                    label={"Phone"}
                    placeholder={"Phone"}
                    required={true}
                    value={phoneNumber}
                    error={phoneError}
                    onChange={(e: any) => {
                      const re = /^[0-9\b]+$/;
                      if (
                        (re.test(e.target.value) &&
                          e.target.value.length < 11) ||
                        !e.target.value
                      ) {
                        onChangeUserDetails(e.target.value, "phone");
                        setPhoneError("");
                      }
                    }}
                  />

                  <HSSelect
                    label={"Organization"}
                    placeholder="Select Organization"
                    // options={org?.orgList}
                    disabled={true}
                    required={true}
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.id}
                    value={organization}
                    onChange={(obj: any) => {
                      setApplications([]);
                      onChangeUserDetails(obj, "organization");
                      setOrgDetails(obj);
                    }}
                  />
                </div>

                {applications?.length < 8 && (
                  <div className="flex self-end text-xl  px-1 rounded-none mb-2 w-full justify-end  ">
                    <Menu>
                      <MenuHandler>
                        <Button
                          className=" w-[280px] flex flex-row items-center  bg-[#264390]  p-2"
                          size="sm"
                          disabled={!isValidUserDetails()}
                          // color="blue"
                          onClick={() => addApplication()}
                        >
                          <ChevronDownIcon className="w-7 h-7 mr-2 " />
                          <span className="text-[15px] font-normal">
                            {" "}
                            Add Application Access{" "}
                          </span>
                        </Button>
                      </MenuHandler>

                      <MenuList
                        className={`w-[280px] p-0  bg-[#264390] text-white boredr border-white rounded-none -mt-2 `}
                      >
                        {orgDetails &&
                          orgDetails?.applications?.map(
                            (menu: any, index: number) => {
                              return (
                                <MenuItem
                                  key={index}
                                  className={`border-b border-white rounded-none 
                          ${
                            isSelected(menu) === -1
                              ? " "
                              : "bg-gray-200 text-gray-600 rounded-none "
                          }`}
                                  onClick={() => onSelectOption(menu, "ADD")}
                                >
                                  {" "}
                                  {menu.applicationName}
                                </MenuItem>
                              );
                            }
                          )}
                      </MenuList>
                    </Menu>
                  </div>
                )}

                {organization &&
                  applications?.map((obj: any, index: number) => {
                    const orgRole =
                      userDetails?.role === USER_ROLES.HIGHSCOPE_ADMIN
                        ? 100000
                        : userDetails?.userDetails?.find(
                            (detail: any) =>
                              detail.application.applicationCode === obj.code
                          )?.application_role.role_id;
                    return (
                      <Accordion
                        key={index}
                        open={open === index}
                        className={`mb-2 rounded-lg border border-blue-gray-100 text-center shadow-xl ${
                          obj.isDelete ? "opacity-10" : ""
                        }`}
                      >
                        <AccordionHeader
                          onClick={() => toggleHeader(index)}
                          className={`bg-[#264390] text-white w-[full] ${
                            open === index
                              ? "rounded-lg rounded-b-none"
                              : "rounded-lg "
                          }   h-[40px] flex flex-row justify-between`}
                          //   className={`border-b-0 transition-colors
                          //    ${
                          //     open === 1 ? "text-blue-500 hover:!text-blue-700" : ""
                          //   }`
                          // }
                        >
                          <div className=" w-full flex flex-row justify-between items-center">
                            <p className="mt-3 px-4 flex flex-row">
                              {obj.headerTitle}
                              {getBadge(obj.code)}
                            </p>
                            <div className=" flex flex-row">
                              <div className="flex text-white">
                                {obj.isDelete ? (
                                  orgRole && (
                                    <ArrowUturnDownIcon
                                      className="w-8 h-8 bg-[#264390] p-1"
                                      onClick={(event) => {
                                        onSelectOption(obj, "UNDO");
                                        event.stopPropagation();
                                      }}
                                    />
                                  )
                                ) : (
                                  <div className="flex text-white">
                                    {/* <PencilSquareIcon className="w-8 h-8 mr-3 bg-[#264390] p-1" /> */}
                                    {orgRole &&
                                      (obj.code === "COR-A" ? (
                                        orgRole === 100000 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 100001 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 100003 &&
                                          obj.selectedRole.role_id !==
                                            100001 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 100004 &&
                                          obj.selectedRole.role_id ===
                                            100004 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )
                                      ) : obj.code === "COR-K" ? (
                                        orgRole === 100000 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 100002 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 100001 &&
                                          obj.selectedRole.role_id !==
                                            100002 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 100004 &&
                                          obj.selectedRole.role_id ===
                                            100004 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )
                                      ) : obj.code === "COR-L" ? (
                                        orgRole === 100000 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 100001 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 100004 &&
                                          obj.selectedRole.role_id ===
                                            100004 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )
                                      ) : obj.code === "COR-C" ? (
                                        orgRole === 100000 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 100001 &&
                                          obj.selectedRole.role_id !==
                                            100002 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 100002 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 100004 &&
                                          obj.selectedRole.role_id ===
                                            100004 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )
                                      ) : obj.code === "COR-R" ? (
                                        orgRole === 100000 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 200001 &&
                                          obj.selectedRole.role_id ===
                                            200001 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 200001 &&
                                          obj.selectedRole.role_id ===
                                            200002 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 200002 &&
                                          (obj.selectedRole.role_id ===
                                            200002 ||
                                            obj.selectedRole.role_id ===
                                              200003) ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 200003 &&
                                          (obj.selectedRole.role_id ===
                                            200003 ||
                                            obj.selectedRole.role_id ===
                                              200004 ||
                                            obj.selectedRole.role_id ===
                                              200005) ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )
                                      ) : obj.code === "COR-P" ? (
                                        orgRole === 100000 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 100001 &&
                                          obj.selectedRole.role_id !==
                                            100004 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 100002 &&
                                          obj.selectedRole.role_id !==
                                            100001 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )
                                      ) : obj.code === "COR-CU" ? (
                                        orgRole === 100000 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 100001 ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : orgRole === 100002 &&
                                          (obj.selectedRole.role_id ===
                                            100002 ||
                                            obj.selectedRole.role_id ===
                                              100004) ? (
                                          <TrashIcon
                                            className="w-8 h-8 bg-[#264390] p-1"
                                            // aria-disabled={true}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              setSelectedApp(obj);
                                              setWarningDialog(true);
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      ))}
                                  </div>
                                )}
                              </div>
                              <ChevronDownIcon className="w-8 h-8 ml-2 items-end" />
                            </div>
                          </div>
                        </AccordionHeader>
                        <AccordionBody className="pt-0 text-base font-normal bg-white relative">
                          {getAccourdianBoady(obj, index)}
                        </AccordionBody>
                      </Accordion>
                    );
                  })}
              </div>
              <div
                className={`flex justify-end px-5 border-t-2 border-gray-400 py-2 h-[60px]`}
              >
                <Button
                  className="flex flex-row items-center justify-center bg-[#264390] hover:bg-gray-500   py-1 px-3 2xl:px-4 2xl:py-3 rounded-md"
                  // disabled={!isApplicationsValid}
                  // disabled={areApplicationRolesSelected()}
                  onClick={updateUser}
                >
                  <span className="text-[15px] 2xl:text-[18px] font-semibold hover:text-[#264390]">
                    UPDATE
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </>

      {selectedApp && warningDialog && (
        <ConfirmationDialog
          open={warningDialog}
          message={
            selectedApp.dbId
              ? "Are you sure? you want to remove the application access for this user? "
              : "Are you sure? You want to delete this application."
          }
          onConfirm={() => onDeleteApp()}
          onCancel={() => setWarningDialog(false)}
        />
      )}

      {applicationWarning && (
        <ConfirmationDialog
          open={applicationWarning}
          message={"At least one application access need to add for user"}
          onConfirm={() => setApplicationWarning(false)}
          onCancel={() => setApplicationWarning(false)}
        />
      )}
    </>
  );
};
