import {
  CREATE_NOTE_FAILED,
  CREATE_NOTE_REQUEST,
  CREATE_ORGNIZATION_FAILED,
  CREATE_ORGNIZATION_LOG_FAILED,
  CREATE_ORGNIZATION_LOG_SUCCESS,
  CREATE_ORGNIZATION_SUCCESS,
  DELETE_ORGNIZATION_FAILED,
  DELETE_ORGNIZATION_SUCCESS,
  GET_ALL_NOTE_FAILED,
  GET_ALL_NOTE_SUCCESS,
  GET_COR_A_DATA_FAILED,
  GET_COR_A_DATA_SUCCESS,
  GET_COR_C_DATA_FAILED,
  GET_COR_C_DATA_SUCCESS,
  GET_COR_K_DATA_SUCCESS,
  GET_CU_DATA_FAILED,
  GET_CU_DATA_SUCCESS,
  GET_LL_DATA_FAILED,
  GET_LL_DATA_SUCCESS,
  GET_ORGANIZATION_FAILED,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGNIZATION_DATA_FAILED,
  GET_ORGNIZATION_DATA_SUCCESS,
  GET_PQA_DATA_FAILED,
  GET_PQA_DATA_SUCCESS,
  GET_RSA_DATA_FAILED,
  GET_RSA_DATA_SUCCESS,
  GET_SINGLE_ORG_DATA_FAILED,
  GET_SINGLE_ORG_DATA_SUCCESS,
  GET_USER_DETAILS_FROM_CORA_FAILED,
  GET_USER_DETAILS_FROM_CORA_SUCCESS,
  GET_USER_DETAILS_FROM_CORK_FAILED,
  GET_USER_DETAILS_FROM_CORK_SUCCESS,
  RECOVER_ORGNIZATION_FAILED,
  RECOVER_ORGNIZATION_SUCCESS,
  RESET_CREATE_ORG_FLAG,
  RESET_UPDATE_ORG,
  START_LOADING,
  STOP_LOADING,
  UPDATE_ORGNIZATION_FAILED,
  UPDATE_ORGNIZATION_SUCCESS,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAILED,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAILED,
  REQUEST_STARTED,
  REQUEST_STOPPED,
} from "../actionConstants";
import { AppDispatch } from "../store";
import { showAlert } from "./app";
import { deleteReq, get, post, postWithOther, put } from "./axios";

export const getAllOrgnization =
  (queryData?: any) => async (dispatch: AppDispatch) => {
    const searchParams = new URLSearchParams(queryData);
    try {
      dispatch({ type: START_LOADING });
      const result = await get(`/org?${searchParams}`, {}, dispatch);
      dispatch({
        type: GET_ORGANIZATION_SUCCESS,
        payload: result,
      });
    } catch (err) {
      //
      dispatch({
        type: GET_ORGANIZATION_FAILED,
      });
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const getAllOrgnizationForDropdown =
  () => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: REQUEST_STARTED });
      const result = await get(`/org/dropdown`, {}, dispatch);

      dispatch({
        type: GET_ORGANIZATION_SUCCESS,
        payload: result,
      });
    } catch (err) {
      //
      dispatch({
        type: GET_ORGANIZATION_FAILED,
      });
    } finally {
      dispatch({ type: REQUEST_STOPPED });
    }
  };

export const getOrgData = (oId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const result = await get(`/org/dropdown/${oId}`, {}, dispatch);
    if (result) {
      dispatch({
        type: GET_SINGLE_ORG_DATA_SUCCESS,
        payload: result,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SINGLE_ORG_DATA_FAILED,
    });
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};

export const createOrgnization =
  (body: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await post(`/org`, body, dispatch);
      dispatch({
        type: CREATE_ORGNIZATION_SUCCESS,
        payload: result,
      });
      dispatch(showAlert("Orgnization created successfully", "success"));
    } catch (err: any) {
      //

      let errorMessage = " ";

      if (err?.response?.data?.message) {
        errorMessage = err?.response?.data.message;
      } else {
        errorMessage =
          "Failed to create orgnization, Please try after sometime or check your inputs.";
      }
      dispatch({
        type: CREATE_ORGNIZATION_FAILED,
      });
      dispatch(showAlert(errorMessage, "error"));
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const createOrgnizationLog =
  (changes: any, body: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await post(`/org/log`, changes, dispatch);
      await dispatch(updateOrgnization(body));
      dispatch({
        type: CREATE_ORGNIZATION_LOG_SUCCESS,
        payload: result,
      });
    } catch (err) {
      //
      dispatch({
        type: CREATE_ORGNIZATION_LOG_FAILED,
      });
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const updateOrgnization =
  (body: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await put(`/org`, body, dispatch);
      dispatch({
        type: UPDATE_ORGNIZATION_SUCCESS,
        payload: result,
      });
      dispatch(showAlert("Orgnization Updated successfully", "success"));
    } catch (err) {
      //
      dispatch({
        type: UPDATE_ORGNIZATION_FAILED,
      });
      dispatch(
        showAlert(
          "Failed to update orgnization, Please try after sometime or check your inputs ",
          "error"
        )
      );
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const resetUpdateOrg = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: RESET_UPDATE_ORG,
  });
};

export const getOrgDetails = (body: any) => async (dispatch: AppDispatch) => {
  try {
    if (!body.applications || body.applications.length === 0) {
      dispatch({
        type: GET_ORGNIZATION_DATA_FAILED,
      });
      throw {
        success: false,
        message: "Application should be available",
        status: 400,
      };
    }
    dispatch({ type: START_LOADING });
    // const result = await post(`/org/orgDetail`, body, dispatch);
    dispatch({
      type: GET_ORGNIZATION_DATA_SUCCESS,
      payload: {},
    });
  } catch (err) {
    //
    dispatch({
      type: GET_ORGNIZATION_DATA_FAILED,
    });
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};

export const resetCreateOrgFlag = () => async (dispatch: AppDispatch) => {
  dispatch({ type: RESET_CREATE_ORG_FLAG });
};

export const deleteOrgnization =
  (id: number) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await deleteReq(`/org/${id}`, {}, dispatch);
      dispatch({
        type: DELETE_ORGNIZATION_SUCCESS,
        payload: id,
      });
      dispatch(showAlert("Orgnization Updated successfully", "success"));
    } catch (err) {
      //
      dispatch({
        type: DELETE_ORGNIZATION_FAILED,
      });
      dispatch(
        showAlert(
          "Failed to delete orgnization, Please try after sometime or check your inputs ",
          "error"
        )
      );
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const recoverOrgnization =
  (id: number) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await put(`/org/${id}`, {}, dispatch);
      dispatch({
        type: RECOVER_ORGNIZATION_SUCCESS,
        payload: id,
      });
      dispatch(showAlert("Orgnization Recoverd successfully", "success"));
    } catch (err) {
      //
      dispatch({
        type: RECOVER_ORGNIZATION_FAILED,
      });
      dispatch(
        showAlert(
          "Failed to delete orgnization, Please try after sometime or check your inputs ",
          "error"
        )
      );
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const getOrgDetailsFromCorK =
  (body: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await postWithOther(
        `${
          process.env.REACT_APP_ENV === "local"
            ? ""
            : process.env.REACT_APP_COR_K_URL
        }/api/admin/org_entities`,
        body,
        dispatch
      );
      dispatch({
        type: GET_COR_K_DATA_SUCCESS,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: GET_COR_A_DATA_FAILED,
      });
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const getUserDetailsFromCorA =
  (body: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await postWithOther(
        `${
          process.env.REACT_APP_ENV === "local"
            ? ""
            : process.env.REACT_APP_COR_A_URL
        }/api/admin/org_entities`,
        body,
        dispatch
      );
      dispatch({
        type: GET_USER_DETAILS_FROM_CORA_SUCCESS,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: GET_USER_DETAILS_FROM_CORA_FAILED,
      });
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const getUserDetailsFromCorK =
  (body: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await postWithOther(
        `${
          process.env.REACT_APP_ENV === "local"
            ? ""
            : process.env.REACT_APP_COR_K_URL
        }/api/admin/org_entities`,
        body,
        dispatch
      );
      if (result) {
        dispatch({
          type: GET_USER_DETAILS_FROM_CORK_SUCCESS,
          payload: result,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_USER_DETAILS_FROM_CORK_FAILED,
      });
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };
export const getOrgDetailsFromPQA =
  (body: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await postWithOther(
        `${
          process.env.REACT_APP_ENV === "local"
            ? ""
            : process.env.REACT_APP_COR_P_URL
        }/api/admin/org_entities`,
        body,
        dispatch
      );
      dispatch({
        type: GET_PQA_DATA_SUCCESS,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: GET_PQA_DATA_FAILED,
      });
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const getOrgDetailsFromRSA =
  (body: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await postWithOther(
        `${
          process.env.REACT_APP_ENV === "local"
            ? ""
            : process.env.REACT_APP_RSA_URL
        }/api/admin/org_entities`,
        body,
        dispatch
      );
      dispatch({
        type: GET_RSA_DATA_SUCCESS,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: GET_RSA_DATA_FAILED,
      });
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const getOrgDetailsFromLL =
  (body: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await postWithOther(
        `${
          process.env.REACT_APP_ENV === "local"
            ? ""
            : process.env.REACT_APP_LETTER_LINK_URL
        }/api/admin/org_entities`,
        body,
        dispatch
      );
      dispatch({
        type: GET_LL_DATA_SUCCESS,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: GET_LL_DATA_FAILED,
      });
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const getOrgDetailsFromCU =
  (body: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await postWithOther(
        `${
          process.env.REACT_APP_ENV === "local"
            ? ""
            : process.env.REACT_APP_CURR_URL
        }/api/admin/org_entities`,
        body,
        dispatch
      );
      dispatch({
        type: GET_CU_DATA_SUCCESS,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: GET_CU_DATA_FAILED,
      });
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const getOrgDetailsFromCorA =
  (body: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await postWithOther(
        `${
          process.env.REACT_APP_ENV === "local"
            ? ""
            : process.env.REACT_APP_COR_A_URL
        }/api/admin/org_entities`,
        body,
        dispatch
      );

      dispatch({
        type: GET_COR_A_DATA_SUCCESS,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: GET_COR_A_DATA_FAILED,
      });
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const getOrgDetailsFromCorC =
  (body: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await postWithOther(
        `${
          process.env.REACT_APP_ENV === "local"
            ? ""
            : process.env.REACT_APP_COR_C_URL
        }/api/admin/org_entities`,
        body,
        dispatch
      );

      if (result) {
        dispatch({
          type: GET_COR_C_DATA_SUCCESS,
          payload: result,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_COR_C_DATA_FAILED,
      });
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const createNote =
  (orgId: number, body: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await post(
        `/note/createNote`,
        { orgId, ...body },
        dispatch
      );
      dispatch({
        type: CREATE_NOTE_REQUEST,
        payload: result,
      });
      return { message: "Note created successfully", success: true };
    } catch (err: any) {
      let errorMessage = " ";

      if (err?.response?.data?.message) {
        errorMessage = err?.response?.data.message;
      } else {
        errorMessage =
          "Failed to create Note, Please try after sometime or check your inputs.";
      }
      dispatch({
        type: CREATE_NOTE_FAILED,
      });
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const getAllNotes = (orgId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const result = await get(`/note/getNotesByOrgId/${orgId}`, {}, dispatch);
    dispatch({
      type: GET_ALL_NOTE_SUCCESS,
      payload: result,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_NOTE_FAILED,
    });
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};

export const updateNote =
  (id: number, body: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await put(`/note/updateNote/${id}`, body, dispatch);
      dispatch({
        type: UPDATE_NOTE_SUCCESS,
        payload: result,
      });
      // dispatch(showAlert("Note updated successfully", "success"));
      return { message: "Note updated successfully", success: true };
    } catch (err: any) {
      let errorMessage = " ";

      if (err?.response?.data?.message) {
        errorMessage = err?.response?.data.message;
      } else {
        errorMessage =
          "Failed to update Note, Please try after sometime or check your inputs.";
      }
      dispatch({
        type: UPDATE_NOTE_FAILED,
      });
      // dispatch(showAlert(errorMessage, "error"));
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const deleteNote = (id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: START_LOADING });
    await deleteReq(`/note/deleteNote/${id}`, {}, dispatch);
    dispatch({
      type: DELETE_NOTE_SUCCESS,
      payload: id,
    });
    // dispatch(showAlert("Note deleted successfully", "success"));
  } catch (err: any) {
    let errorMessage = " ";

    if (err?.response?.data?.message) {
      errorMessage = err?.response?.data.message;
    } else {
      errorMessage =
        "Failed to delete Note, Please try after sometime or check your inputs.";
    }
    dispatch({
      type: DELETE_NOTE_FAILED,
    });
    // dispatch(showAlert(errorMessage, "error"));
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};
