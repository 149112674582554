import { getAzurToken } from "../../utils";
import {
  CREATE_USER_FAILED,
  CREATE_USER_SUCCESS,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
  GET_SINGLE_ORG_DATA_USER_FAILED,
  GET_SINGLE_ORG_DATA_USER_SUCCESS,
  FILE_UPLOAD_COMPLETED,
  FILE_UPLOAD_STARTED,
  GET_USER_DATA_EDIT_FAILED,
  GET_USER_DATA_EDIT_SUCCESS,
  GET_USER_DETAILS_FAILED,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_LIST_FAILED,
  GET_USER_LIST_SUCCESS,
  RECOVER_USER_FAILED,
  RECOVER_USER_SUCCESS,
  RESET_CREATE_USER_STATUS,
  RESET_UPDATE_USER_FLAG,
  START_LOADING,
  STOP_LOADING,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  REQUEST_STARTED,
  REQUEST_STOPPED,
} from "../actionConstants";
import { AppDispatch } from "../store";
import { showAlert } from "./app";
import { deleteReq, get, post, postImage, put } from "./axios";

export const getUserDetails =
  (azurId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await get(`/user/user-details/${azurId}`, {}, dispatch);
      dispatch({
        type: GET_USER_DETAILS_SUCCESS,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: GET_USER_DETAILS_FAILED,
      });
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const registerUser = (body: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const result = await post(`/user`, body, dispatch);
    dispatch({
      type: CREATE_USER_SUCCESS,
      payload: result,
    });
    dispatch(showAlert("User created successfully", "success"));
  } catch (err: any) {
    dispatch({
      type: CREATE_USER_FAILED,
    });
    dispatch(
      showAlert(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Failed to create User, Please try after sometime or check your inputs",
        "error"
      )
    );
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};

export const getUserList =
  (queryData: any, orgId: any) => async (dispatch: AppDispatch) => {
    const searchParams = new URLSearchParams(queryData);
    // const result = await get(`/campus/?${searchParams}`);
    try {
      dispatch({ type: START_LOADING });
      const result = await get(
        `user/user-list/${orgId}?${searchParams}`,
        {},
        dispatch
      );
      dispatch({
        type: GET_USER_LIST_SUCCESS,
        payload: result,
      });
    } catch (err) {
      dispatch({
        type: GET_USER_LIST_FAILED,
      });
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const resetCreateUserdata = () => async (dispatch: AppDispatch) => {
  dispatch({ type: RESET_CREATE_USER_STATUS });
};
export const updateUserDetails =
  (body: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const result = await put(`/user`, body, dispatch);
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: result,
      });
      dispatch(showAlert("User Updated successfully", "success"));
    } catch (err: any) {
      dispatch({
        type: UPDATE_USER_FAILED,
      });
      dispatch(
        showAlert(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : "Failed to create User, Please try after sometime or check your inputs",
          "error"
        )
      );
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };

export const resetUpdateUserFlag = () => async (dispatch: AppDispatch) => {
  dispatch({ type: RESET_UPDATE_USER_FLAG });
};

export const deleteUser = (id: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const result: any = await deleteReq(`/user/${id}`, {}, dispatch);
    if (result.success) {
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: id,
      });
      dispatch(showAlert("User Delete successfully", "success"));
    }
  } catch (err: any) {
    dispatch({
      type: DELETE_USER_FAILED,
    });

    dispatch(
      showAlert(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Failed to delete User, Please try after sometime or check your inputs",
        "error"
      )
    );
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};

export const recoverUser = (id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const result: any = await put(`/user/restore-user/${id}`, {}, dispatch);
    if (result.success) {
      dispatch({
        type: RECOVER_USER_SUCCESS,
        payload: id,
      });
      dispatch(showAlert("User Recoverd successfully", "success"));
    }
  } catch (err: any) {
    dispatch({
      type: RECOVER_USER_FAILED,
    });
    dispatch(
      showAlert(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : "Failed to create User, Please try after sometime or check your inputs",
        "error"
      )
    );
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};

export const getUserData = (uId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const result = await get(`/user/userData/${uId}`, {}, dispatch);
    if (result) {
      dispatch({
        type: GET_USER_DATA_EDIT_SUCCESS,
        payload: result,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_USER_DATA_EDIT_FAILED,
    });
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};

// Test
export const getOrgDataForDropdoenUser =
  (oId: any) => async (dispatch: AppDispatch) => {
    try {
      // Debugging log
      dispatch({ type: REQUEST_STARTED });
      const result = await get(`/org/dropdown/${oId}`, {}, dispatch);

      if (result) {
        dispatch({
          type: GET_SINGLE_ORG_DATA_USER_SUCCESS,
          payload: result,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_SINGLE_ORG_DATA_USER_FAILED,
      });
      dispatch({ type: REQUEST_STOPPED });
    } finally {
      dispatch({ type: REQUEST_STOPPED });
    }
  };

export const uploadProfilePicture =
  (userId: any, file: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const tokenData = getAzurToken();
      dispatch({ type: FILE_UPLOAD_STARTED });
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "profile");
      formData.append("id", userId);
      const result = await postImage(`/user/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + tokenData,
        },
      });
      // if (result && result.url) {
      //   dispatch(editUsers({ id: userId, profilePicture: result }));
      // }
      dispatch({ type: FILE_UPLOAD_COMPLETED });
      // dispatch(getUserById(userId));
    } catch (error) {
      dispatch({ type: FILE_UPLOAD_COMPLETED });
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };
