import axios from "axios";
import { getAzurToken } from "../../utils";
import { SESSION_EXPIRED_ERROR } from "../actionConstants";

const instance = axios.create({
  // baseURL: "http://localhost:8080/",
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 1000000,
});
const instanceWithNoAuth = axios.create({
  timeout: 60000,
});

const instanceForImage = axios.create({
  timeout: 60000,
});

// Add a request interceptor
instance.interceptors.request.use(
  async (config) => {
    const tokenData = getAzurToken();
    if (tokenData) {
      config.headers["Authorization"] = "Bearer " + tokenData;
      config.headers["Content-Type"] = "application/json";
      config.headers["Access-Control-Allow-Origin"] = "*";
    }
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
      config.headers["Access-Control-Allow-Origin"] = "*";
    }
    return config;
  },
  (error) => {
    console.log("error in instance: ", error);
    return Promise.reject(error);
  }
);

export const get = (url: string, config: any, dispatch: any) => {
  return new Promise((resolve, reject) => {
    instance
      .get(url, config)
      .then((result) => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch((error) => {
        console.log("Error in get Main ", error);
        if (
          error.response.data &&
          error.response.data.statusCode === 401 &&
          error.response.data.message === "Unauthorized"
        ) {
          dispatch({ type: SESSION_EXPIRED_ERROR });
        }

        reject(error);
      });
  });
};

export const post = (url: string, data: any, dispatch: any) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, data)
      .then((result) => {
        resolve(result.data);
        // if (isSuccessful(result.status)) {
        //   resolve(result.data);
        // }
      })
      .catch((error) => {
        console.log("Error in Post", error);
        if (
          error.response.data &&
          error.response.data.statusCode === 401 &&
          error.response.data.message === "Unauthorized"
        ) {
          dispatch({ type: SESSION_EXPIRED_ERROR });
        }
        reject(error);
      });
  });
};

export const put = (url: string, data: any, dispatch: any) => {
  return new Promise((resolve, reject) => {
    instance
      .put(url, data)
      .then((result) => {
        resolve(result.data);
        // if (isSuccessful(result.status)) {
        //   resolve(result.data);
        // }
      })
      .catch((error) => {
        console.log("Error in Post", error);
        if (
          error.response.data &&
          error.response.data.statusCode === 401 &&
          error.response.data.message === "Unauthorized"
        ) {
          dispatch({ type: SESSION_EXPIRED_ERROR });
        }
        reject(error);
      });
  });
};

export const deleteReq = (url: string, data: any, dispatch: any) => {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, data)
      .then((result) => {
        resolve(result.data);
        // if (isSuccessful(result.status)) {
        //   resolve(result.data);
        // }
      })
      .catch((error) => {
        console.log("Error in Post", error);
        if (
          error.response.data &&
          error.response.data.statusCode === 401 &&
          error.response.data.message === "Unauthorized"
        ) {
          dispatch({ type: SESSION_EXPIRED_ERROR });
        }
        reject(error);
      });
  });
};

export const postWithOther = (url: string, data: any, dispatch: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {})
      .then((result) => {
        resolve(result.data);
        // if (isSuccessful(result.status)) {
        //   resolve(result.data);
        // }
      })
      .catch((error) => {
        console.log("Error in Post", error);
        if (
          error.response?.data &&
          error.response.data.statusCode === 401 &&
          error.response.data.message === "Unauthorized"
        ) {
          dispatch({ type: SESSION_EXPIRED_ERROR });
        }
        reject(error);
      });
  });
};

export const postImage = (url: any, data: any, config: any) => {
  return new Promise((resolve, reject) => {
    axios

      .post(process.env.REACT_APP_API_URL + url, data, config)
      .then((result) => {
        resolve(result.data);
        // if (isSuccessful(result.status)) {
        //   resolve(result.data);
        // }
      })
      .catch((error) => {
        console.log("Error in Post", error);
        reject(error);
      });
  });
};
