//UserInterface to define the State type for the state of the reducer

import { GET_CLASSROOM_FAILED, GET_CLASSROOM_SUCCESS, GET_SERVICE_CENTER_FAILED, GET_SERVICE_CENTER_SUCCESS, GET_SITE_FAILED, GET_SITE_SUCCESS, GET_SUB_RECIPIENT_FAILED, GET_SUB_RECIPIENT_SUCCESS } from "../actionConstants";

interface AppInterface {
    siteList: any;
    serviceCenterList: any;
    subRecipientList: any;
    classroomList: any;
}

interface Actions {
    payload: any;
    type: string;
    token: string;
}

//State type for defining the state of the reducer
export type State = AppInterface;

//Initial state of the reducer of type State
export const initialState: State = {
    siteList: [],
    serviceCenterList: [],
    subRecipientList: [],
    classroomList: [],
};

export const CCReducers = (state: State = initialState, action: Actions) => {

    switch (action.type) {
        //if action is of type SIGN_IN or SIGN_UP return the state by setting token to the payload
        case GET_SITE_SUCCESS:
            return {
                ...state,
                siteList: action.payload.data,
            };
        case GET_SITE_FAILED:
            return {
                ...state,
                userList: [],
            };
        case GET_SERVICE_CENTER_SUCCESS:
            return {
                ...state,
                serviceCenterList: action.payload.data,
            };
        case GET_SERVICE_CENTER_FAILED:
            return {
                ...state,
                serviceCenterList: [],
            };
        case GET_SUB_RECIPIENT_SUCCESS:
            return {
                ...state,
                subRecipientList: action.payload.data,
            };
        case GET_SUB_RECIPIENT_FAILED:
            return {
                ...state,
                subRecipientList: [],
            };
        case GET_CLASSROOM_SUCCESS:
            return {
                ...state,
                classroomList: action.payload.data,
            };
        case GET_CLASSROOM_FAILED:
            return {
                ...state,
                classroomList: [],
            };
        default:
            return state;
    }
};
