
import { GET_CLASSROOM_FAILED, GET_CLASSROOM_SUCCESS, GET_SITE_FAILED, GET_SITE_SUCCESS, START_LOADING, STOP_LOADING } from "../actionConstants";
import { AppDispatch } from "../store";
import { postWithOther } from "./axios";

export const getSitesDetailsFromCorC = (body: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const result = await postWithOther(
      `${process.env.REACT_APP_ENV === "local"
        ? ""
        : process.env.REACT_APP_COR_C_URL
      }/api/admin/sso`,
      body,
      dispatch
    );

    if (result) {
      dispatch({
        type: GET_SITE_SUCCESS,
        payload: result,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_SITE_FAILED,
    });
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};

export const getClassroomDetailsFromCorC = (body: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const result = await postWithOther(
      `${process.env.REACT_APP_ENV === "local"
        ? ""
        : process.env.REACT_APP_COR_C_URL
      }/api/admin/sso`,
      body,
      dispatch
    );

    if (result) {
      dispatch({
        type: GET_CLASSROOM_SUCCESS,
        payload: result,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CLASSROOM_FAILED,
    });
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};